var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container hero" }, [
    _vm._v(" Initials "),
    _c(
      "div",
      { staticClass: "columns hero-body has-text-centered is-centered" },
      [
        _c(
          "div",
          { staticClass: "column is-half" },
          [
            _c("b-loading", {
              attrs: {
                active: _vm.isLoading,
                "can-cancel": false,
                "is-full-page": false
              },
              on: {
                "update:active": function($event) {
                  _vm.isLoading = $event
                }
              }
            }),
            !_vm.isLoading && _vm.isVerified
              ? _c("div", { staticClass: "card1" }, [
                  _c(
                    "div",
                    { staticClass: "card-content" },
                    [
                      _c(
                        "b-notification",
                        {
                          attrs: {
                            type: "is-success",
                            "has-icon": "",
                            closable: false,
                            "aria-close-label": "Close notification"
                          }
                        },
                        [_vm._v(" Email address verified ")]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("div", { staticClass: "box" }, [
                        _c("h3", { staticClass: "title has-text-black" }, [
                          _vm._v(" Set a password to your account ")
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.new_password,
                                  expression: "new_password"
                                }
                              ],
                              staticClass: "input ",
                              attrs: {
                                type: "password",
                                placeholder: "Password"
                              },
                              domProps: { value: _vm.new_password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.new_password = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confirm_password,
                                  expression: "confirm_password"
                                }
                              ],
                              staticClass: "input ",
                              attrs: {
                                type: "password",
                                placeholder: "Password"
                              },
                              domProps: { value: _vm.confirm_password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.confirm_password = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-block is-info is-pulled-right",
                            on: { click: _vm.setPassword }
                          },
                          [_vm._v(" Save ")]
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            !_vm.isLoading && !_vm.isVerified
              ? _c("div", { staticClass: "card" }, [_vm._m(0)])
              : _vm._e()
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("p", [_vm._v(" Not a valid tokem ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }