<template>
  <div class="container hero">
    Initials
    <div class="columns hero-body has-text-centered is-centered">
      <div class="column is-half">
        <b-loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="false"
        />
        <div
          v-if="!isLoading && isVerified"
          class="card1"
        >
          <div class="card-content">
            <b-notification
              type="is-success"
              has-icon
              :closable="false"
              aria-close-label="Close notification"
            >
              Email address verified
            </b-notification>
            <br><br>
            <div class="box">
              <h3 class="title has-text-black">
                Set  a password to your account
              </h3>

              <div class="field">
                <div class="control">
                  <input
                    v-model="new_password"
                    class="input "
                    type="password"
                    placeholder="Password"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    v-model="confirm_password"
                    class="input "
                    type="password"
                    placeholder="Password"
                  >
                </div>
              </div>

              <button
                class="button is-block is-info is-pulled-right"
                @click="setPassword"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!isLoading && !isVerified"
          class="card"
        >
          <div class="card-content">
            <p> Not a valid tokem </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
		uid: {
			type: String,
			required: true
		},
		token: {
			type: String,
			required: true
		}
	},
  data() {
    return {
      isLoading: true,
      isVerified: false,
      new_password: "",
      confirm_password: ""
    };
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    validateToken() {
      this.isLoading = true;

      if (this.token == null) {
        return;
      }

      var url = "/users/activation";
      let _data = {uid:this.uid, token: this.token}

      axios
        .post(url, _data)
        .then(response => {
          this.isLoading = false;
          this.isVerified = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.isLoading = false;
          this.isVerified = false;
        });
    },
    setPassword() {
      this.isLoading = true;

      if (this.token == null) {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'Invalid token',
          });
        return;
      }
      if(this.new_password != this.confirm_password) {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'Passwords doesn\'t match. Please retype again.',
          });
        return;
      }

      var url = "/users/set_activation_password";
      let _data = {uid:this.uid, token: this.token, new_password: this.new_password}

      axios
        .post(url, _data)
        .then(response => {
          this.isLoading = false;
          this.$router.push({
            name: 'login',
          });

        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>